import { useForm } from "react-hook-form";
import { useAppSelector } from "../store/hooks";
import { useEffect, useMemo, useState } from "react";
import { getOptions } from "../utill/helpers";
import {
  useCreateDocumentMutation,
  useGetCategoriesQuery,
  useUploadFileMutation,
} from "../services/rtkQuery/documents";
import { selectCategories } from "../store/modules/documents/selectors";
import { useGetOrganizationsQuery } from "../services/rtkQuery/organization";
import { selectOrganizationUsers } from "../store/modules/organization/selectors";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getGeneralSelectBoxSchema, getOptionalSelectBoxSchema } from "../utill/validation/validationSchemas";
import { toast } from "react-toastify";
import { Option } from "../view/ui/common/SelectBox";

const documentSchema = Yup.object().shape({
  title: Yup.string().required("Document name is required"),
  organizationId: getOptionalSelectBoxSchema(),
  categoryId: getGeneralSelectBoxSchema("Category is required"),
});

const defaultValues = {
  categoryId: { value: "", label: "" },
  title: "",
  status: "active",
  organizationId: { value: "", label: "" },
};

export const useCreateDocument = (file: any, onSuccess: () => void, id?: number | undefined) => {
  const [errorUploading, setErrorUploading] = useState("");
  const [isFileAdded, setIsFileAdded] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(documentSchema) });

  useGetOrganizationsQuery({ getAll: true });
  useGetCategoriesQuery();
  const [uploadFile, { isLoading: isFileLoading, isSuccess: isFileUploadSuccess }] =
    useUploadFileMutation();
  const [createDocument] = useCreateDocumentMutation();
  const organizationList = useAppSelector(selectOrganizationUsers);
  const categories = useAppSelector(selectCategories);

  const organizationOptions = useMemo(() => {
    if (organizationList && organizationList.rows) {
      return getOptions(organizationList?.rows, "name", "id");
    }
    return [];
  }, [organizationList?.rows]);

  useEffect(() => {
    if (id) {
      reset({
        organizationId: organizationOptions.find((el: Option) => el.value === String(id)),
      });
    }
  }, [id, organizationOptions]);

  const categoriesOptions = useMemo(() => {
    if (categories && categories.length) {
      return getOptions(categories, "name", "id");
    }
    return [];
  }, [categories]);

  useEffect(() => {
    if (file) {
      setIsFileAdded(true);
    } else {
      setIsFileAdded(false);
    }
  }, [file]);

  useEffect(() => {
    if (isFileUploadSuccess) {
      toast.success("New document was added");
    }
  }, [isFileUploadSuccess]);

  const onSubmit = (data: any) => {
    if (isFileAdded) {
      const formData = new FormData();
      formData.append("file", file);
      uploadFile(formData).then((res: any) => {
        if (res.error) {
          setErrorUploading(res.error.error);
          return;
        }
        data.url = res.data.data;
        data.status = "active";
        data.type = file.type;
        data.organizationId = data.organizationId.value || null;
        data.categoryId = data.categoryId.value;
        createDocument(data).then(() => onSuccess());
      });
    } else {
      toast.warn("Please add your document");
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    errors,
    organizationOptions,
    categoriesOptions,
    errorUploading,
    isFileLoading,
  };
};
