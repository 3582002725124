import React, { useState } from "react";
import { SearchInput } from "../SearchInput";
import BellIcon from "../../assets/icons/BellIcon";
import ChevronIcon from "../../assets/icons/ChevronIcon";
import { NavLink } from "react-router-dom";
import LogoIcon from "../../assets/icons/Logo";
import { selectUser } from "../../../store/modules/user/selectors";
import { useAppSelector } from "../../../store/hooks";
import { Avatar } from "./Avatar";

const Header = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const user = useAppSelector(selectUser);
  const isLenderUser = user?.type === "lenderUser";
  const isBorrower = user?.type === "borrower_user";
  const isBorrowerAdmin = user?.type === "borrower_admin";

  const isBorrowerGeneralUser = isBorrower && user.role !== "admin";

  const handleChange = (value: string | number) => {
    setSearchQuery(value.toString());
  };

  return (
    <div className="flex justify-between items-center py-4 px-4">
      <div className="flex items-center w-[90%]">
        <div className="w-[15%] py-4 flex">
          <LogoIcon height={"50px"} />
        </div>
        <div className="ml-6 w-[90%] text-[15px]">
          <ul className="flex gap-5 items-center justify-between">
            {!isLenderUser && !isBorrower && !isBorrowerAdmin && (
              <NavLink
                to="/employee"
                className={({ isActive }) =>
                  isActive ? "border-b-[orange] border-b-2 text-[orange]" : ""
                }
              >
                Carewolves
              </NavLink>
            )}
            {!isBorrower && !isBorrowerAdmin && (
              <>
                <NavLink
                  to="/organization"
                  className={({ isActive }) =>
                    isActive ? "border-b-[orange] border-b-2 text-[orange]" : ""
                  }
                >
                  School Partners
                </NavLink>
              </>
            )}

            {!isBorrowerGeneralUser && (
              <NavLink
                to="/borrower"
                className={({ isActive }) =>
                  isActive ? "border-b-[orange] border-b-2 text-[orange]" : ""
                }
              >
                School Partner Contacts
              </NavLink>
            )}

            <NavLink
              to="/documents"
              className={({ isActive }) =>
                isActive ? "border-b-[orange] border-b-2 text-[orange]" : ""
              }
            >
              Documents
            </NavLink>
            <li>
              <NavLink
                to="/help"
                className={({ isActive }) =>
                  isActive ? "border-b-[orange] border-b-2 text-[orange]" : ""
                }
              >
                Training
              </NavLink>
            </li>
            <a
              target="_blank"
              href="https://form.jotform.com/242554974103154"
              className="w-[17%] flex justify-center text-center items-center text-[#4D5187] border-[#4D5187] h-[40px] border-[2px] rounded-lg font-medium"
            >
              Annual Report
            </a>
            <a
              target="_blank"
              href="https://form.jotform.com/232403195049150"
              className="w-[17%] flex justify-center text-center items-center text-[#4D5187] border-[#4D5187] h-[40px] border-[2px] rounded-lg font-medium"
            >
              Quarterly Report
            </a>
          </ul>
        </div>
      </div>
      <div className="flex w-[15%] justify-center">
        <div className="flex items-center gap-3 ml-5">
          <Avatar />
        </div>
      </div>
    </div>
  );
};

export default Header;
